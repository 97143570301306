.fadeIn-enter,
.fadeIn-appear {
  opacity: 0;
}

.fadeIn-enter-active,
.fadeIn-appear-active {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.fadeIn-exit {
  opacity: 1;
}

.fadeIn-exit-active {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
