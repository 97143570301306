.DayPicker {
  min-height: 316px;
}

.DayPicker_weekHeader {
  color: var(--primary400);
  top: 70px;
}

.DayPicker_weekHeader_ul {
  font-size: 2rem;
}

.DayPicker_weekHeader_li {
  text-transform: capitalize;
  font-weight: normal;
}

.CalendarMonth_caption {
  padding-top: 12px;
  padding-bottom: 70px;
  max-width: 130px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-transform: capitalize;
}

.DayPickerNavigation {
  display: flex;
  justify-content: space-between;
}

.DayPickerNavigation_button {
  margin-right: 40px;
  margin-left: 40px;
}

.CalendarDay__default {
  border: 1px double var(--grey200);
}

.CalendarDay__default:hover {
  background: var(--grey100);
  border: 1px solid var(--grey200);
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:hover {
  background-image: url(../images/calendarDaySelectedSpan.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid transparent;
  color: var(--terciary500);
}

.CalendarDay__selected {
  background: none;
  background-image: url(../images/calendarDaySelected.svg);
  background-repeat: no-repeat;
  background-position: center;
  color: #484848;
}

.CalendarDay__selected:hover {
  background: var(--grey200);
  background-image: url(../images/calendarDaySelected.svg);
  background-repeat: no-repeat;
  background-position: center;
  color: #484848;
  border: 1px double var(--grey200);
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--terciary400);
  color: var(--terciary500);
  border: 1px double transparent;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  color: var(--grey200);
}
