.slideRight-appear,
.slideRight-enter {
  transform: translate3d(107%, 0, 0);
}

.slideRight-appear-active,
.slideRight-enter-active {
  transform: translate3d(0, 0, 0);
  transition: transform 250ms cubic-bezier(0.11, 0.88, 0.35, 0.97);
}

.slideRight-exit {
  transform: translate3d(0, 0, 0);
}

.slideRight-exit-active {
  transform: translate3d(107%, 0, 0);
  transition: transform 250ms cubic-bezier(0.11, 0.88, 0.35, 0.97);
}
